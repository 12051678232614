import {create} from 'zustand'
import {persist} from "zustand/middleware";
// import {createJSONStorage} from "zustand/middleware";

const usePreferenceStore = create(persist((set) => ({
	
	paymentListStyle: 'compact',
	
	setPaymentListStyle: (value) => set({paymentListStyle: value}),
	togglePaymentListStyle: () => set((state) => ({paymentListStyle: state.paymentListStyle === 'compact' ? 'simple' : 'compact'})),
	
}), {
	name: 'x-preference', // name of item in the storage (must be unique)
	//storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
	partialize: (state) => ({
		paymentListStyle: state.paymentListStyle,
	}),
}))

export default usePreferenceStore;