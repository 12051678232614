import React from 'react';

function ProductsDetails({payment}) {
	if (!payment?.products?.length) return null;
	return <div className="py-4 sm:grid sm:grid-cols-1 sm:gap-2">
		<dt className="text-sm font-medium leading-6 text-gray-900">Products Details</dt>
		<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 p-3 bg-gray-100 shadow rounded-lg">
			<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th scope="col" className="py-3.5 pl-4 ltr:pr-3 rtl:pl-3 ltr:text-left rtl:text-right text-xs font-semibold uppercase text-gray-600 sm:pl-0">
									Product
								</th>
								<th scope="col" className="px-3 py-3.5 ltr:text-left rtl:text-right font-semibold uppercase text-xs text-gray-600">
									Amount
								</th>
								<th scope="col" className="px-3 py-3.5 ltr:text-left rtl:text-right font-semibold uppercase text-xs text-gray-600">
									VAT%
								</th>
								<th scope="col" className="px-3 py-3.5 ltr:text-left rtl:text-right font-semibold uppercase text-xs text-gray-600">
									Quantity
								</th>
								<th scope="col" className="px-3 py-3.5 ltr:text-left rtl:text-right font-semibold uppercase text-xs text-gray-600">
									Total
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
							{payment?.products?.map((product) => (
								<tr key={product.id}>
									<td className="whitespace-nowrap py-4 pl-4 ltr:pr-3 rtl:pl-3 text-sm font-medium text-gray-900 sm:pl-0">
										{product.title}
										<div className="text-gray-500">{product?.details}</div>
									</td>
									<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product?.amount} {payment?.currency?.symbol}</td>
									<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product?.vat === 0 ? '' : product.vat}</td>
									<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product?.quantity}</td>
									<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product?.total} {payment?.currency?.symbol || ''}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</dd>
	</div>
}

export default ProductsDetails;