import React from 'react';
import {formatNumberFloat} from "../utils/helpers";
import {clsx} from "clsx";

function MoneyHighlight({value, currency, className}) {
    const locale = ['DA', 'DZD'].includes(currency) ? 'fr-DZ' : 'en-US';
    let formattedValue = formatNumberFloat(value, locale);

    return (
        <span
            className={clsx(className, "bg-clip-text print:text-black text-transparent bg-gradient-to-r from-green-500 to-blue-500 font-bold text-xl")}>{formattedValue} {currency || ''}</span>
    );
}

export default MoneyHighlight;