import {CheckCircleIcon} from '@heroicons/react/20/solid'
import {clsx} from "clsx";
import React from "react";

function MutationStatusMessage({mutation}) {
	if (!mutation || mutation.isIdle) return null;
	
	let message = "Loading...";
	if (mutation.isError) message = "Error occurred while processing your request. Please, try again.";
	if (mutation.isSuccess) message = "Form has been submitted successfully!";
	
	message = mutation.isError ? mutation?.error?.response?.data?.message : message;
	
	return (
		<div
			className={clsx(
				mutation.isError ? 'bg-red-50' : '',
				mutation.isSuccess ? 'bg-green-50' : '',
				"rounded-md p-4 bg-gray-50"
			)}
		>
			<div className="flex">
				<div className="flex-shrink-0">
					<CheckCircleIcon
						className={clsx(
							mutation.isError ? 'text-red-400' : '',
							mutation.isSuccess ? 'text-green-400' : '',
							"h-5 w-5 text-gray-400"
						)}
						aria-hidden="true"/>
				</div>
				<div className="ml-3">
					<p
						className={clsx(
							mutation.isError ? 'text-red-800' : '',
							mutation.isSuccess ? 'text-green-800' : '',
							"text-sm font-medium text-gray-800"
						)}
					>
						{message}
					</p>
				</div>
			</div>
		</div>
	)
}

export default MutationStatusMessage
