import React from 'react';
import DashboardLayout from "../../layouts/DashboardLayout";

function Notification() {
	return (
		<DashboardLayout>
			Notifications
		</DashboardLayout>
	);
}

export default Notification;

