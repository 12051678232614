import React, {useMemo} from 'react';
import {ArrowDownTrayIcon, PhotoIcon, TrashIcon} from "@heroicons/react/24/solid";
import {http} from "../../../utils/http";
import ButtonLink from "../../ButtonLink";
import Button from "../../Button";
import Loading from "../../Loading";
import {cn} from "../../../utils/style";

function RegistrationFileField({field, show, get, set}) {
	const [uploading, setUploading] = React.useState(false);
	const handleFileUpload = async (event) => {
		setUploading(true);
		try {
			const file = event.target.files[0];
			if (!file) return;
			const formData = new FormData();
			formData.append('file', file);
			const response = await http.post('/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			const {fileUrl} = response?.data;
			if (fileUrl) {
				set(field.fieldName, fileUrl);
			}
		} catch (error) {
			console.error(error);
		}
		setUploading(false);
	}
	
	const file = useMemo(() => {
		return get[field.fieldName] || null;
	}, [get, field]);
	
	return (
		<div className={cn("sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4", {
			'sm:grid': show,
			'hidden': !show,
		})}>
			<label htmlFor={field.fieldName} className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
				{field.displayName} <span className="text-red-500/50 text-[10px]" aria-label="Required">{field.required ? '(Required)' : ''}</span>
			</label>
			<div className="relative mt-2 sm:col-span-2 sm:mt-0 overflow-hidden">
				{uploading && <div className="backdrop-blur-sm z-40 w-full h-full absolute top-0 left-0 bg-white/20 flex justify-center items-center">
					<Loading message="Uploading ..."/>
				</div>}
				
				{file && <div>
					<div className="mb-1 overflow-x-auto">
						<p className="text-sm text-gray-500 font-mono leading-6 tracking-tight">
							{file}
						</p>
					</div>
					<div className="flex gap-3 items-center">
						<ButtonLink href={file} target="_blank" className="flex items-center justify-center gap-2">
							<ArrowDownTrayIcon className="h-4 w-4 text-white"/>
							<span>Download</span>
						</ButtonLink>
						<Button secondary={true} onClick={() => set(field.fieldName, null)} className="flex items-center justify-center gap-2">
							<TrashIcon className="h-4 w-4 text-white"/>
							<span>Clear</span>
						</Button>
					</div>
				</div>}
				
				{!file && <div className="flex max-w-2xl justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 bg-white">
					<div className="text-center">
						<PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true"/>
						<div className="mt-4 flex text-sm leading-6 text-gray-600">
							<label
								htmlFor={field.fieldName}
								className="relative cursor-pointer rounded-md bg-white font-semibold text--blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring--blue-500 focus-within:ring-offset-2 hover:text-indigo-500"
							>
								<span>Upload a file</span>
								<input id={field.fieldName} name={field.fieldName} onChange={handleFileUpload} type="file" className="sr-only"/>
							</label>
							<p className="pl-1">or drag and drop</p>
						</div>
						<p className="text-xs leading-5 text-gray-600">PDF, JPG up to 10MB</p>
					</div>
				</div>}
			</div>
		</div>
	);
}

export default RegistrationFileField;