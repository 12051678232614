import React from 'react';
import PageLayout from "../layouts/PageLayout";

function ConditionsOfUseSatim() {
    return (<PageLayout title="Conditions Générales d'Utilisation - PAYD.DZ">
        <h2 className="mb-5 mt-8 font-bold text-lg">1. DÉFINITIONS</h2>
        <h3 className="my-2 font-semibold">1.1 Lien de Paiement</h3>
        <p className="text-gray-600">Le lien de paiement est un URL ou un QR Code généré par le MARCHAND pour
            permettre à un CLIENT
            d'effectuer le paiement d'un bien ou service via la plateforme PAYD.DZ. Le CLIENT peut scanner le QR
            Code ou cliquer sur le lien pour accéder à la plateforme de paiement.</p>
        <h3 className="my-2 font-semibold">1.2 Marchand</h3>
        <p className="text-gray-600">Un MARCHAND est toute personne physique ou morale possédant un Registre de
            commerce et un compte
            bancaire commercial, autorisée à utiliser PAYD.DZ pour les paiements en ligne, après obtention de
            l'autorisation auprès de la SATIM et GIE Monétique.</p>
        <h3 className="my-2 font-semibold">1.3 Client</h3>
        <p className="text-gray-600">Le CLIENT est un e-consommateur, porteur d'une carte CIB ou EDAHABIA,
            utilisée pour effectuer des
            paiements en ligne à travers PAYD.DZ.</p>
        <h2 className="mb-5 mt-8 font-bold text-lg">2. CONDITIONS D'UTILISATION POUR LES MARCHANDS</h2>
        <h3 className="my-2 font-semibold">2.1 Inscription</h3>
        <p className="text-gray-600">Tout MARCHAND souhaitant utiliser PAYD.DZ doit s'inscrire sur notre
            plateforme et obtenir
            l'autorisation d'intégration de paiement en ligne via le site https://www.cibweb.dz. Deux catégories
            de MARCHANDS
            sont
            proposées :</p>
        <ul>
            <li className="text-gray-600 list-disc">Marchand Externe : MARCHAND ayant opté pour une inscription
                indépendante.
            </li>
            <li className="text-gray-600 list-disc">Marchand Interne : MARCHAND affilié via un contrat
                d'encaissement pour compte.
            </li>
        </ul>
        <h3 className="my-2 font-semibold">2.2 Acceptation des Paiements</h3>
        <p className="text-gray-600">Les MARCHANDS utilisant PAYD.DZ peuvent accepter les paiements via carte
            CIB ou EDAHABIA. En
            utilisant
            notre passerelle, vous confirmez que vous êtes le propriétaire légitime du compte utilisé et
            acceptez
            les frais associés.</p>
        <h3 className="my-2 font-semibold">2.3 Responsabilité du Marchand</h3>
        <p className="text-gray-600">Le MARCHAND est responsable de la véracité des informations fournies et de
            l'usage légitime de
            PAYD.DZ.
            Tout usage frauduleux ou contraire aux lois est strictement interdit.</p>

        <h2 className="mb-5 mt-8 font-bold text-lg">3. FRAIS ET COMMISSIONS</h2>
        <p className="text-gray-600">L'utilisation de PAYD.DZ est soumise à des frais de transaction, détaillés
            lors de la confirmation du
            paiement. Vous acceptez de régler tous les frais conformément à notre grille tarifaire.</p>
        <h2 className="mb-5 mt-8 font-bold text-lg">4. SÉCURITÉ ET CONFORMITÉ</h2>
        <p className="text-gray-600">Nous garantissons la sécurité de vos transactions et respectons les normes
            de sécurité en vigueur
            (PCI
            DSS). Le CLIENT et le MARCHAND doivent également se conformer aux lois applicables et ne pas tenter
            de
            compromettre la sécurité du système.</p>

        <h2 className="mb-5 mt-8 font-bold text-lg">5. POLITIQUE DE REMBOURSEMENT</h2>
        <h3 className="my-2 font-semibold">5.1 Éligibilité</h3>
        <p className="text-gray-600">Les demandes de remboursement sont acceptées pour des erreurs de paiement
            ou transactions
            incorrectes,
            dans un délai de [insérer le délai].</p>
        <h3 className="my-2 font-semibold">5.2 Procédure</h3>
        <p className="text-gray-600">Les demandes de remboursement doivent être soumises via notre application
            mobile ou web. Le MARCHAND
            peut accepter ou rejeter la demande avec un motif clair. En cas de désaccord, le CLIENT peut nous
            contacter par email à remboursement@payd.dz.</p>
        <h2 className="mb-5 mt-8 font-bold text-lg">6. PROPRIÉTÉ INTELLECTUELLE</h2>
        <p className="text-gray-600">Le contenu de la plateforme PAYD.DZ est protégé par les lois sur la
            propriété intellectuelle. Toute
            utilisation sans notre autorisation est strictement interdite.</p>
    </PageLayout>);
}

export default ConditionsOfUseSatim;