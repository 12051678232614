import React, {useEffect, useMemo} from 'react';
import DashboardLayout from "../../layouts/DashboardLayout";
import {useTranslation} from "react-i18next";
import PageHeader from "../../components/PageHeader";
import StepPanel from "../../components/registration/StepPanel";
import GeneralInformation from "../../components/registration/GeneralInformation";
import UploadDocument from "../../components/registration/UploadDocument";
import BankInformation from "../../components/registration/BankInformation";
import ButtonLong from "../../components/ButtonLong";
import useRegistrationFormStore from "../../store/registration-form";
import {useMutation, useQuery} from "react-query";
import {http} from "../../utils/http";
import {apollo} from "../../utils/apollo";
import {
    GET_MERCHANT_REGISTRATION_FIELDS,
    GET_REGISTRATION_COUNTRIES,
    GET_REGISTRATION_FORM_FIELDS,
    UPDATE_REGISTRATION
} from "../../utils/graphql";
import useAuthStore from "../../store/auth";
import RegistrationStringField from "../../components/registration/fields/RegistrationStringField";
import RegistrationFileField from "../../components/registration/fields/RegistrationFileField";
import RegistrationCountryField from "../../components/registration/fields/RegistrationCountryField";
import RegistrationBooleanField from "../../components/registration/fields/RegistrationBooleanField";
import {clsx} from "clsx";
import Loading from "../../components/Loading";
import {toast} from "react-toastify";
import {cn} from "../../utils/style";

function Registration() {
    const {t} = useTranslation();
    const {
        step, setStep, credentials, setCredentials, dynamicFormValue, setDynamicFormValue,
        setDynamicFormValues,
    } = useRegistrationFormStore()
    const {user, merchant} = useAuthStore();

    // const fetchRegistration = useQuery({
    // 	queryKey: ['registration'],
    // 	queryFn: () => http.get("/merchant/registration", {
    // 		params: {}
    // 	}),
    // 	keepPreviousData: false
    // });
    // const records = fetchRegistration?.data?.data || null;
    const fetchCountries = useQuery({
        queryKey: 'registration_countries',
        queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_REGISTRATION_COUNTRIES,
                variables: {},
            });
            return data?.getRegistrationCountries;
        },
        refetchOnWindowFocus: false
    });

    const fetchFields = useQuery({
        queryKey: 'merchant_registration_fields',
        queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_MERCHANT_REGISTRATION_FIELDS,
                variables: {
                    country: merchant?.country
                },
            });
            return data;
        },
        enabled: !!merchant?.country,
        refetchOnWindowFocus: false
    });

    const fields = useMemo(() => {
        return fetchFields?.data?.getRegistrationForm || null;
    }, [fetchFields]);

    useEffect(() => {
        const list = fetchFields?.data?.getMerchantRegistrationFields || null;
        if (list && !Object.keys(dynamicFormValue).length) {
            let dynValues = {};
            list?.forEach(item => {
                const key = item?.fieldDefinition?.fieldName || null;
                const val = item?.fieldValue || null;
                if (key && val) dynValues[key] = val;
            })
            if (Object.keys(dynValues).length) {
                setDynamicFormValues(dynValues);
            }
        }
    }, [fetchFields]);

    const updateRegistrationMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: UPDATE_REGISTRATION,
                variables: {
                    input: {
                        fields: JSON.stringify({country: merchant?.country, ...dynamicFormValue})
                    }
                },
            });
            return data?.updateRegistration;
        },
        onSuccess: (data) => {
            if (data && data.success) {
                toast.success('Registration has been updated successfully.');
            } else if (data && data.message) {
                toast.error(data.message)
            } else {
                toast.error('Please check the submitted information again!')
            }
        },
        // onError: ((error) => {
        //     if (error.graphQLErrors) {
        //         error.graphQLErrors.map((item) => console.log(item));
        //         toast.error('Something went wrong!')
        //     }
        // })
    })

    const submitRegistrationForm = async (e) => {
        e.preventDefault();
        updateRegistrationMutation.mutate();
    }

    return (
        <DashboardLayout>
            <PageHeader title={t('registration.page_title')} description={t('registration.page_description')}>
            </PageHeader>

            {(fetchFields.isLoading || fetchCountries.isLoading) && <Loading/>}

            {(fetchFields.isSuccess && fetchCountries) && <div className="mt-6">
                <StepPanel step={step + 1} onClick={value => setStep(value - 1)}/>
            </div>}

            {(fetchFields.isSuccess && fetchCountries) && <div className="mt-6">
                <div className="mt-10 space-y-8 pb-12 sm:space-y-0 sm:pb-0">
                    {fields?.map(field => {
                        if (field.fieldType === 'string') return <RegistrationStringField key={field.id} field={field}
                                                                                          show={step + 1 === field.displayOnSection}
                                                                                          get={dynamicFormValue}
                                                                                          set={setDynamicFormValue}/>
                        if (field.fieldType === 'file') return <RegistrationFileField key={field.id} field={field}
                                                                                      show={step + 1 === field.displayOnSection}
                                                                                      get={dynamicFormValue}
                                                                                      set={setDynamicFormValue}/>
                        if (field.fieldType === 'country') return <RegistrationCountryField key={field.id} field={field}
                                                                                            show={step + 1 === field.displayOnSection}
                                                                                            get={merchant?.country}
                                                                                            set={null} readOnly={true}
                                                                                            countries={fetchCountries?.data}/>
                        // if (field.fieldType === 'country') return <RegistrationStringField key={field.id} field={field} show={step === field.displayOnSection} get={dynamicFormValue} set={setDynamicFormValue} defaultValue={registrationCountry} readOnly={true}/>
                        if (field.fieldType === 'boolean') return <RegistrationBooleanField key={field.id} field={field}
                                                                                            show={step + 1 === field.displayOnSection}
                                                                                            get={dynamicFormValue}
                                                                                            set={setDynamicFormValue}/>
                        return null
                    })}
                </div>
                <div className={cn("items-center sm:gap-4 sm:py-4", {
                    'flex': step < 3,
                    'hidden': step >= 3,
                })}>
                    {step < 2 && <ButtonLong className="text-lg w-full max-w-sm"
                                             onClick={() => setStep(step + 1)}>Continue</ButtonLong>}
                    {step === 2 && <ButtonLong className="text-lg w-full max-w-sm" onClick={submitRegistrationForm}>Update
                        registration</ButtonLong>}
                </div>
            </div>}

        </DashboardLayout>
    );
}

export default Registration;