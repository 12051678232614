import React, {useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import Pagination from "../../../components/Pagination";
import SortIcon from "../../../components/SortIcon";
import StatusBadge from "../../../components/StatusBadge";
import {Link} from "react-router-dom";
import Loading from "../../../components/Loading";
import {apollo} from "../../../utils/apollo";
import {GET_PAYMENT_GATEWAY_LIST} from "../../../utils/graphql";

function PaymentGateway() {
	const {t} = useTranslation();
	const [sort, setSort] = useState({field: 'id', isAscending: false});
	const [page, setPage] = useState(1);
	const [showFilter, setShowFilter] = useState(false);
	const [statusFilter, setStatusFilter] = useState('')
	
	const tableHeader = [
		{column: 'payment_gateway_uuid', title: t('payment_gateway.table.payment_gateway_uuid'), sortable: true},
		// {column: 'code', title: t('payment_gateway.table.code'), sortable: true},
		{column: 'name', title: t('payment_gateway.table.name'), sortable: true},
		{column: 'credentials', title: t('payment_gateway.table.credentials'), sortable: true},
		{column: 'active', title: t('payment_gateway.table.active'), sortable: true},
		{column: 'created_at', title: t('payment_gateway.table.date'), sortable: true},
		{column: 'view', title: t('payment_gateway.table.view'), sortable: false, hidden: true},
	]
	
	const fetchPaymentGatewayList = useQuery(
		['users_list', {page, sort, statusFilter}],
		async () => {
			const {data} = await apollo.query({
				query: GET_PAYMENT_GATEWAY_LIST,
				variables: {
					sortBy: sort.field?.toUpperCase(),
					sortDirection: sort.isAscending ? "ASC" : "DESC",
					page,
					first: 15,
					filter: {
						status: statusFilter
					}
				},
			});
			return data?.getPaymentGatewayList;
		},
		{keepPreviousData: false}
	);
	const records = fetchPaymentGatewayList?.data || null;
	
	const handleChangeSort = (field) => {
		if (sort.field === field) {
			setSort(prevState => ({...prevState, isAscending: !prevState.isAscending}));
		} else {
			setSort({field: field, isAscending: true});
		}
	};
	
	return (
		<DashboardLayout>
			<PageHeader title={t('payment_gateway.page_title')} description={t('payment_gateway.page_description')}>
				<div className="flex items-center gap-3">
					{/*<IconButton onClick={() => setShowFilter(!showFilter)}>*/}
					{/*	<FunnelIcon className="w-5 h-5 text-blue-500"/>*/}
					{/*</IconButton>*/}
					{/*<ButtonInternalLink href="/merchant/payment-gateway/create">{t('payment_gateway.create_payment_gateway')}</ButtonInternalLink>*/}
				</div>
			</PageHeader>
			{showFilter && <div className="mt-3 shadow-md bg-white border rounded px-4 py-2">
                filter
			</div>}
			{fetchPaymentGatewayList.isLoading && <Loading/>}
			{(fetchPaymentGatewayList.isSuccess && records) && <>
				<Pagination paginator={records?.paginatorInfo} get={page} set={setPage}/>
				<div className="flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table className="min-w-full divide-y divide-gray-300">
								<thead>
									<tr>
										{tableHeader?.map(item => (<th key={item.column} scope="col" className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
											{!item.hidden && <>
												{item.sortable ? <button onClick={() => handleChangeSort(item.column)} type="button" className="group inline-flex">
													{item.title}
													<SortIcon sort={sort} field={item.column}/>
												</button> : <span>{item.title}</span>}
											</>}
										</th>))}
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200">
									{records?.data?.map((record) => (<tr key={record?.paymentGatewayUuid} className="hover:bg-gray-200/60">
										<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{record?.paymentGatewayUuid}</td>
										{/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.code}</td>*/}
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.name}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{JSON.stringify(record?.credentials)}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><StatusBadge status={record?.active ? 'active' : 'inactive'}/></td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.createdAt}</td>
										<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0">
											<Link to={`/merchant/payment-gateway/view/${record?.paymentGatewayUuid}`} className="text-blue-500 hover:text-blue-900">View</Link>
										</td>
									</tr>))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<Pagination paginator={records?.paginatorInfo} get={page} set={setPage}/>
			</>}
		</DashboardLayout>
	);
}

export default PaymentGateway;

