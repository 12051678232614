import React from 'react';

function Tabby({invoice}) {
//     address
// city
// zipCode
// country
    return (
        <div className="sm:flex">
            <div className="mb-3 sm:w-4/12">Tabby Details</div>
            <div className="mb-3 sm:w-8/12">
                <div className="">
                    <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Address
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <input
                            defaultValue={invoice?.address || ''}
                            id="address"
                            name="address"
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:max-w-md sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>


                <div className="">
                    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        City
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <input
                            defaultValue={invoice?.city || ''}
                            id="city"
                            name="city"
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:max-w-md sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>


                <div className="">
                    <label htmlFor="zip_code" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Zip Code
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <input
                            defaultValue={invoice?.zipCode || ''}
                            id="zip_code"
                            name="zip_code"
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:max-w-md sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>


            </div>
        </div>

    );
}

export default Tabby;