import React, {useEffect} from 'react';
import useMainStore from "../store/store";
import {useNavigate} from "react-router-dom";

function Home() {
	const {appTitle} = useMainStore();
	const navigate = useNavigate();
	
	// const showToast = () => {
	// 	// toast({
	// 	// 	title: "Button Clicked",
	// 	// 	description: "You have clicked the button",
	// 	// 	status: "info",
	// 	// 	duration: 5000,
	// 	// 	isClosable: true,
	// 	// 	position: "bottom-right",
	// 	// });
	// }
	
	useEffect(() => {
		navigate("/login")
	}, []);
	
	return (
		<div>
			Welcome to {appTitle}
		</div>
	);
}

export default Home;