import React from 'react';
import TextInput from "./TextInput";
import {TrashIcon} from "@heroicons/react/24/outline";

function ProductFormItem({product, updateProduct, deleteProduct}) {
    const id = product?.id;
    return (
        <div className="flex gap-2">
            <div className="w-full grid gap-x-4 gap-y-6 grid-cols-12 sm:mb-6 bg-gray-200/50 p-3 rounded-lg shadow">
                <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                    <TextInput name={`product-title-${id}`} label="Product Title" id={`product-title-${id}`} type="text"
                               get={product?.title} set={value => updateProduct(id, 'title', value)}/>
                </div>

                <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                    <TextInput name={`product-details-${id}`} label="Product Details" id={`product-details-${id}`}
                               type="text" get={product?.details} set={value => updateProduct(id, 'details', value)}/>
                </div>

                <div className="col-span-6 sm:col-span-4 lg:col-span-2">
                    <TextInput name={`product-amount-${id}`} label="Amount" id={`product-amount-${id}`} type="number"
                               get={product?.amount} set={value => updateProduct(id, 'amount', value)}/>
                </div>

                <div className="col-span-6 sm:col-span-4 lg:col-span-1">
                    <TextInput name={`product-vat-${id}`} label="VAT %" id={`product-vat-${id}`} type="number"
                               get={product?.vat} set={value => updateProduct(id, 'vat', value)}/>
                </div>

                <div className="col-span-6 sm:col-span-4 lg:col-span-1">
                    <TextInput name={`product-quantity-${id}`} label="Quantity" id={`product-quantity-${id}`}
                               type="number" get={product?.quantity}
                               set={value => updateProduct(id, 'quantity', value)}/>
                </div>

                <div className="col-span-6 sm:col-span-4 lg:col-span-2">
                    <TextInput name={`product-total-${id}`} label="Total Amount" id={`product-total-${id}`}
                               type="number" get={product?.total} set={value => updateProduct(id, 'total', value)}/>
                </div>
            </div>

            <div className="flex-shrink-0 w-8 flex items-center justify-center">
                <div className="p-2 group hover:bg-rose-500 rounded cursor-pointer" onClick={() => deleteProduct(id)}>
                    <TrashIcon className="w-5 h-5 text-rose-500 group-hover:text-white"/>
                </div>
            </div>
        </div>
    );
}

export default ProductFormItem;