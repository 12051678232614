import React from "react";
import {useTranslation} from "react-i18next";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import {cn} from "../utils/style";

function PaginationFull({paginator, get, set}) {
    const {t} = useTranslation()
    const {currentPage, lastPage} = paginator;
    if (currentPage === 1 && lastPage === 1) {
        return <div className="mt-3"></div>
    }

    return (
        <nav className="flex flex-col sm:flex-row items-center justify-between gap-4 w-full mt-6">
            <div>

                <p className="text-sm text-gray-700">
                    Page <span className="font-medium">{currentPage}</span> / <span
                    className="font-medium">{lastPage}</span>
                </p>
            </div>
            <div>
                <nav aria-label="Pagination" className="isolate inline-flex -space-x-px rounded-md shadow-sm">
                        <span
                            onClick={e => {
                                if (currentPage > 1) set(currentPage - 1)
                                else set(1)
                            }}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-500 cursor-pointer ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon aria-hidden="true" className="h-5 w-5"/>
                        </span>

                    <div>
                        {getPageNumbers(lastPage, currentPage).map((number, index) => (
                            <React.Fragment key={index}>
                                {number === null ? (
                                    <PaginationGap/>
                                ) : (
                                    <PaginationItem paginator={paginator} number={number} set={set}/>
                                )}
                            </React.Fragment>
                        ))}
                    </div>


                    <span
                        onClick={e => {
                            if (currentPage < lastPage) set(currentPage + 1)
                            else set(currentPage)
                        }}
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-500 cursor-pointer ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon aria-hidden="true" className="h-5 w-5"/>
                        </span>
                </nav>
            </div>
        </nav>
    )
}


function PaginationGap() {
    return <button
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
        ...
    </button>;
}

function PaginationItem({number, paginator, set}) {
    const {currentPage, lastPage} = paginator;

    return <button
        onClick={() => set(number)} disabled={currentPage === number}
        className={cn(
            "relative cursor-pointer inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0",
            {
                "bg-gradient-to-br text-white from-green-500 to-blue-500 hover:from-green-400 hover:to-blue-400 focus-visible:outline-blue-600 ring-blue-400": currentPage === number
            }
        )}
    >
        {number}
    </button>
}


function getPageNumbers(totalPages, currentPage) {
    let pageNumbers = [];

    if (totalPages <= 6) {
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    } else if (currentPage < 3) {
        pageNumbers = [1, 2, 3, null, totalPages - 2, totalPages - 1, totalPages];
    } else if (currentPage === 3) {
        pageNumbers = [1, 2, 3, 4, null, totalPages - 1, totalPages];
    } else if (currentPage > totalPages - 2) {
        pageNumbers = [1, 2, 3, null, totalPages - 2, totalPages - 1, totalPages];
    } else if (currentPage === totalPages - 2) {
        pageNumbers = [1, 2, null, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    } else {
        pageNumbers = [1, null, currentPage - 1, currentPage, currentPage + 1, null, totalPages];
    }

    return pageNumbers;
}

export default PaginationFull;
