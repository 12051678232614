export function getWhiteLabelName() {
    return process.env.REACT_APP_WHITE_LABEL || 'PAYD';
}

export function getLogoFileName() {
    const whiteLabel = getWhiteLabelName();
    if (whiteLabel && whiteLabel !== 'PAYD') {
        return `${whiteLabel.toLowerCase()}-logo.png`;
    }
    return 'logo.png';
}


export function getAppName() {
    return process.env.REACT_APP_APP_NAME || 'PAYD';
}

export function getFaviconFileName() {
    const whiteLabel = getWhiteLabelName();
    if (whiteLabel && whiteLabel !== 'PAYD') {
        return `${whiteLabel.toLowerCase()}-favicon.ico`;
    }
    return 'favicon.ico';
}

export function setWhiteLabelMetadata() {
    document.title = getAppName();
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = `/${getFaviconFileName()}`;
    document.head.appendChild(link);
}