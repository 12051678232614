import React from 'react';
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/16/solid";
import IconButtonLink from "./IconButtonLink";
import {clsx} from "clsx";

function PageHeader({back, title, description, children, className}) {
	return (
		<div className={clsx(className, "sm:flex sm:items-end")}>
			<div className="sm:flex-auto items-center">
				<div className="flex items-center gap-3">
					{back && <IconButtonLink href={back}>
						<ArrowRightIcon className="ltr:hidden h-5 w-5 text-blue-500" aria-hidden="true"/>
						<ArrowLeftIcon className="rtl:hidden h-5 w-5 text-blue-500" aria-hidden="true"/>
					</IconButtonLink>}
					<h1 className="text-base font-semibold leading-6 text-gray-900">{title}</h1>
				</div>
				{description && <p className="mt-2 text-sm text-gray-700">{description}</p>}
			</div>
			{children && <div className="mt-4 ltr:sm:ml-16 rtl:sm:mr-16 sm:mt-0 sm:flex-none">
				{children}
			</div>}
		</div>
	);
}

export default PageHeader;