import React, {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {QueueListIcon} from '@heroicons/react/24/outline'
import SelectInput from "./SelectInput";
import {http} from "../utils/http";
import ButtonLong from "./ButtonLong";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const START_YEAR = 2024;
const YEARS = [...Array(new Date().getFullYear() - START_YEAR + 1)];

const CURRENT_DAY = `0${new Date().getDate()}`.slice(-2);
const CURRENT_MONTH = `0${new Date().getMonth() + 1}`.slice(-2);
const CURRENT_YEAR = new Date().getFullYear().toString();

function PaymentReportGenerateDialog({active, toggle, filter}) {
	const [filterBy, setFilterBy] = useState('daily');
	const [date, setDate] = useState({day: CURRENT_DAY, month: CURRENT_MONTH, year: CURRENT_YEAR});
	const [loading, setLoading] = useState(false);
	
	// http://api.payd.local/api/v1/merchant/payment-download-reports
	// download report from the link above using axios
	
	const downloadReportHandler = () => {
		setLoading(true);
		http.get(`/merchant/payment-download-reports`, {
			params: {
				day: date.day,
				month: date.month,
				year: date.year,
				filterBy,
			}
		})
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				const fileName = `downloaded report.csv`;
				link.setAttribute('download', fileName)
				document.body.appendChild(link)
				link.click()
				link.remove()
			})
			.catch(error => {
				alert('Something went wrong!')
			})
			.finally(() => {
				setLoading(false)
			})
	}
	// const createPaymentReportUrlMutation = useMutation({
	// 	mutationFn: async () => {
	// 		const {data} = await apollo.mutate({
	// 			mutation: GET_PAYMENT_REPORT_URL_WITH_DATE,
	// 			variables: {
	// 				input: {
	// 					month: date.month,
	// 					year: date.year,
	// 				}
	// 			},
	// 		});
	// 		return data?.getPaymentReportUrl;
	// 	},
	// 	onSettled: (data) => {
	// 		console.log({data})
	// 	}
	// 	// onSuccess: () => {
	// 	// 	queryClient.invalidateQueries('payment_links').then(() => {
	// 	// 		setTimeout(() => navigate("/merchant/payments"), 2000);
	// 	// 	});
	// 	// },
	// })
	
	
	// useEffect(() => {
	//   if (link && !open && link !== paymentLink) {
	//     setOpen(true)
	//     setPaymentLink(link)
	//   }
	// }, [link]);
	
	return (
		<Transition.Root show={active} as={Fragment}>
			<Dialog as="div" className="relative z-50" onClose={toggle}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
				</Transition.Child>
				
				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
								<div>
									<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
										<QueueListIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
									</div>
									<div className="mt-3 sm:mt-5">
										<Dialog.Title as="h3" className="text-center text-base font-semibold leading-6 text-gray-900">
											Payment Reports
										</Dialog.Title>
										<SelectInput name="filter-by" label="Filter By" id="filter-by" get={filterBy} set={setFilterBy}>
											{/*<option value="filter">Current filter</option>*/}
											<option value="daily">Day</option>
											<option value="monthly">Month</option>
										</SelectInput>
										{filterBy === 'filter' && <div className="mt-3 grid grid-cols-1 gap-3">
											{Object.entries(filter).filter(([key, value]) => value !== '').map(([key, value]) => (
												<p key={key}><span className="text-gray-600 font-semibold capitalize">{key}</span>: <span className="text-gray-500">{value}</span></p>
											))}
										</div>}
										{filterBy === 'daily' && <div className="mt-3 grid grid-cols-2 gap-3">
											<DatePicker
												className="block disabled:opacity-60 disabled:bg-gray-200 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
												selected={new Date(`${date.year}-${date.month}-${date.day}`)}
												onChange={(selectedDate) => {
													setDate({
														day: `0${selectedDate.getDate()}`.slice(-2),
														month: `0${selectedDate.getMonth() + 1}`.slice(-2),
														year: selectedDate.getFullYear().toString(),
													});
												}}
												dateFormat="yyyy-MM-dd"
											/>
										</div>}
										{filterBy === 'monthly' && <div className="mt-3 grid grid-cols-2 gap-3">
											<DatePicker
												className="block disabled:opacity-60 disabled:bg-gray-200 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
												selected={new Date(`${date.year}-${date.month}-${date.day}`)}
												onChange={(selectedDate) => {
													setDate({
														day: `0${selectedDate.getDate()}`.slice(-2),
														month: `0${selectedDate.getMonth() + 1}`.slice(-2),
														year: selectedDate.getFullYear().toString(),
													});
												}}
												dateFormat="MMMM, yyyy"
												showMonthYearPicker
											/>
										</div>}
									</div>
								</div>
								<div className="mt-5 sm:mt-6">
									<ButtonLong disabled={loading} type="button" onClick={downloadReportHandler}>
										Download
									</ButtonLong>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default PaymentReportGenerateDialog;