import React from 'react';
import {PlusIcon} from "@heroicons/react/24/solid";

function UpgradeBusinessPlus() {
	return (
		<div className="flex text-gray-600 gap-2 items-center min-h-[300px] justify-center font-semibold">
			Upgrade to <span className="flex items-center text-white px-2 py-0.5 rounded-md font-semibold bg-gradient-to-r from-rose-700 to-rose-600">PAYD Business <PlusIcon className="h-5 w-5"/></span>
		</div>
	);
}

export default UpgradeBusinessPlus;