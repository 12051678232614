import React from 'react';
import {useTranslation} from "react-i18next";
import {formatNumberFloat} from "../../../../utils/helpers";
import StatusBadge from "../../../../components/StatusBadge";
import {PaperAirplaneIcon, EyeIcon, FlagIcon} from "@heroicons/react/24/solid";
import SortIcon from "../../../../components/SortIcon";
import {PAYD_URL} from "../../../../utils/env";
import {Link} from "react-router-dom";

function SimplePaymentList({handleChangeSort, records, sort, setLinkToShow}) {
	const {t} = useTranslation();
	const tableHeader = [
		{column: 'payment_uuid', title: t('payment.table.payment_uuid'), sortable: true},
		{column: 'name', title: t('payment.table.name'), sortable: true}, // {column: 'products', title: t('payment.table.products'), sortable: true},
		{column: 'status', title: t('payment.table.status'), sortable: true},
		{column: 'amount', title: t('payment.table.amount'), sortable: true},
		{column: 'test', title: t('payment.table.live'), sortable: true},
		{column: 'anti_fraud', title: t('payment.table.antiFraud'), sortable: false},
		{column: 'auth_code', title: t('payment.table.auth_code'), sortable: false},
		{column: 'created_at', title: t('payment.table.date'), sortable: true},
		{column: 'remark', title: t('payment.table.remark'), sortable: false},
		{column: 'view', title: t('payment.table.view'), sortable: false, hidden: true},]
	return <div className="flow-root">
		<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
				<table className="min-w-full divide-y divide-gray-300">
					<thead>
						<tr>
							{tableHeader?.map(item => (<th key={item.column} scope="col" className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900">
								{!item.hidden && <>
									{item.sortable ? <button onClick={() => handleChangeSort(item.column)} type="button" className="group inline-flex">
										{item.title}
										<SortIcon sort={sort} field={item.column}/>
									</button> : <span>{item.title}</span>}
								</>}
							</th>))}
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200">
						{records?.data?.map((record) => (<tr key={record?.paymentUuid} className="hover:bg-gray-50">
							<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{record?.paymentUuid}</td>
							<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
								<div className="max-w-[150px] truncate ">{record?.name}</div>
							</td>
							<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><StatusBadge status={record?.status}/></td>
							<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ltr:text-right rtl:text-left">{formatNumberFloat(record?.amount)} {record?.currency?.symbol || ''}</td>
							<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-[30px]">
								<StatusBadge status={!record?.test} boolean={true}/>
							</td>
							<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
								{record?.antiFraud && <FlagIcon className="h-5 w-5 text-rose-500"/>}
							</td>
							<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.authCode}</td>
							<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{record?.createdAt}</td>
							<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
								<div className="line-clamp-2 text-pretty max-w-[200px]">{record?.remarks}</div>
							</td>
							<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0 flex gap-2 items-center">
								<div className="text-white bg-gradient-to-br from-red-400 to-orange-400 hover:from-red-300 hover:to-orange-300 select-none cursor-pointer p-1 rounded-md" onClick={() => {
									setLinkToShow(`${PAYD_URL}/pay/${record?.paymentUuid}`)
								}}>
									<PaperAirplaneIcon className="w-5 h-5"/>
								</div>
								<Link to={`/merchant/payments/view/${record?.paymentUuid}`} className="text-white bg-gradient-to-br from-red-400 to-orange-400 hover:from-red-300 hover:to-orange-300 select-none p-1 rounded-md">
									<EyeIcon className="w-5 h-5 "/>
								</Link>
							</td>
						</tr>))}
					</tbody>
				</table>
			</div>
		</div>
	</div>;
}

export default SimplePaymentList;