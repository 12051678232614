import React from 'react';
import DashboardLayout from "../../layouts/DashboardLayout";
import UpgradeBusinessPlus from "../../components/UpgradeBusinessPlus";
import {GET_SECRET_KEYS} from "../../utils/graphql";
import {apollo} from "../../utils/apollo";
import {useQuery} from "react-query";

function Setting() {
	// apiKey
	const fetchSecretKeys = useQuery({
		queryKey: 'secret_keys',
		queryFn: async () => {
			const {data} = await apollo.query({
				query: GET_SECRET_KEYS,
				variables: {},
			});
			return data?.getSecretKeys?.data;
		},
		keepPreviousData: false
	});
	const secretKeys = fetchSecretKeys?.data || null;
	return (
		<DashboardLayout>
			{/*<UpgradeBusinessPlus/>*/}
			<div className="space-y-12 sm:space-y-16">
				<div>
					<div className="mt-10 space-y-10 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
						<fieldset>
							<legend className="sr-only">Secret keys</legend>
							<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
								<div className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
									Secret keys
								</div>
								<div className="mt-4 sm:col-span-2 sm:mt-0">
									<div className="max-w-lg space-y-6">
										<div className="relative flex flex-col gap-4">
											{fetchSecretKeys.isSuccess && secretKeys?.map((secretKey, index) => <div key={secretKey.apiKey} className="text-sm">
												<div>
													<span className="text-gray-600 font-semibold">Api Key:</span>
													<span className="px-1 text-gray-500">{secretKey.apiKey}</span>
												</div>
												<div>
													<span className="text-gray-600 font-semibold">Mode:</span>
													<span className="px-1 text-gray-500">{secretKey.testMode === '1' ? 'Test' : 'Live'}</span>
												</div>
												<div>
													<span className="text-gray-600 font-semibold">Created At:</span>
													<span className="px-1 text-gray-500">{secretKey.createdAt}</span>
												</div>
											</div>)}
										</div>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
				</div>
			</div>
		
		</DashboardLayout>
	);
}

export default Setting;

