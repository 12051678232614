import React from 'react';
import {clsx} from "clsx";

function ButtonLong({type, children, onClick, secondary, className, disabled}) {
	return (
		<button
			onClick={e => {
				e.preventDefault();
				onClick(e);
			}}
			type={type || 'submit'}
			disabled={disabled}
			className={clsx(
				className,
				disabled ? 'disabled:opacity-60' : '',
				secondary
					? 'from-red-500 to-orange-500 hover:from-red-400 hover:to-orange-400'
					: 'from-green-500 to-blue-500 hover:from-green-400 hover:to-blue-400',
				"flex w-full justify-center rounded-md bg-gradient-to-br px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500")}
		>
			{children}
		</button>
	);
}

export default ButtonLong;