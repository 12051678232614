import React, {useMemo} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {useParams} from 'react-router-dom';
import {useQuery} from "react-query";
import StatusBadge from "../../../components/StatusBadge";
import Loading from "../../../components/Loading";
import {apollo} from "../../../utils/apollo";
import {GET_SETTLEMENT} from "../../../utils/graphql";
import Button from "../../../components/Button";
import InformationLine from "../../../components/InformationLine";
import MoneyHighlight from "../../../components/MoneyHighlight";

function SettlementView() {
	const {settlement_uuid} = useParams();
	
	const fetchSettlement = useQuery(
		['settlement', {settlement_uuid}],
		async () => {
			const {data} = await apollo.query({
				query: GET_SETTLEMENT,
				variables: {
					settlement_uuid,
				},
			});
			return data?.getSettlement;
		},
		{keepPreviousData: false}
	);
	const settlement = useMemo(() => fetchSettlement?.isSuccess ? fetchSettlement?.data : null, [fetchSettlement]);
	
	return (
		<DashboardLayout>
			<PageHeader back="/merchant/settlements" title="Settlement View" description="This page provides you with the capability to generate new payment links for seamless transactions."/>
			
			{fetchSettlement.isLoading && <Loading/>}
			{(fetchSettlement.isSuccess && settlement) && <div className="mt-6 border-t border-gray-100">
				<dl className="divide-y divide-gray-100">
					<InformationLine title="Payment Uuid" value={settlement?.settlementUuid}/>
					<InformationLine title="Merchant" value={settlement?.merchant?.merchantName}/>
					<InformationLine title="User" value={settlement?.user?.name}/>
					<InformationLine title="Authority" value={settlement?.authority?.name}/>
					<InformationLine title="Request Amount" value={<MoneyHighlight value={settlement?.requestAmount} currency={settlement?.currency?.symbol}/>}/>
					<InformationLine title="Settlement Amount" value={!!settlement?.settlementAmount
						? <MoneyHighlight value={settlement?.settlementAmount} currency={settlement?.currency?.symbol} className="!text-2xl"/>
						: 'N/A'
					}/>
					<InformationLine title="Settlement Date" value={settlement?.settlementDate}/>
					<InformationLine title="Status" value={<StatusBadge status={settlement?.status}/>}/>
					<InformationLine title="Remarks" value={settlement?.remarks}/>
					<InformationLine title="Created At" value={settlement?.createdAt}/>
				</dl>
			</div>}
		
		</DashboardLayout>
	);
}

export default SettlementView;



