import {ArrowLongLeftIcon, ArrowLongRightIcon} from '@heroicons/react/20/solid'
import React from "react";
import {useTranslation} from "react-i18next";

function Pagination({paginator, get, set}) {
	const {t} = useTranslation()
	const {currentPage, lastPage} = paginator;
	if (currentPage === 1 && lastPage === 1) {
		return <div className="mt-3"></div>
	}
	return (
		<nav className="mt-3 flex items-center justify-between px-4 sm:px-0">
			<div className="flex w-0 flex-1">
				<div
					onClick={e => {
						if (currentPage > 1) set(currentPage - 1)
						else set(1)
					}}
					className="cursor-pointer select-none inline-flex items-center border-transparent p-3 bg-gray-200/60 hover:bg-gray-200 rounded text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
				>
					<ArrowLongLeftIcon className="rtl:hidden mr-3 h-5 w-5 text-gray-400" aria-hidden="true"/>
					<ArrowLongRightIcon className="ltr:hidden ml-3 h-5 w-5 text-gray-400" aria-hidden="true"/>
					{t('pagination.previous')}
				</div>
			</div>
			<div className="hidden md:-mt-px md:flex text-xs text-gray-500">
				Page: {currentPage} / {lastPage}
			</div>
			<div className="flex w-0 flex-1 justify-end">
				<div
					onClick={e => {
						if (currentPage < lastPage) set(currentPage + 1)
						else set(currentPage)
					}}
					className="cursor-pointer select-none inline-flex items-center border-transparent p-3 bg-gray-200/60 hover:bg-gray-200 rounded text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
				>
					{t('pagination.next')}
					<ArrowLongRightIcon className="rtl:hidden ml-3 h-5 w-5 text-gray-400" aria-hidden="true"/>
					<ArrowLongLeftIcon className="ltr:hidden mr-3 h-5 w-5 text-gray-400" aria-hidden="true"/>
				</div>
			</div>
		</nav>
	)
}

export default Pagination;