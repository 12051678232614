import React from 'react';

function TransactionLine({transaction}) {
    return (
        <tr key={transaction?.id} className="hover:bg-gray-50">
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction?.merchantReference ?? ''}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction?.transactionId ?? ''}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction?.authCode ?? ''}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction?.cardNumber ?? ''}</td>
            {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction?.gatewayType ?? ''}</td>*/}
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction?.status ?? ''}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction?.description ?? ''}</td>
            {/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction?.log ?? ''}</td>*/}
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{transaction?.createdAt ?? ''}</td>
        </tr>
    );
}

export default TransactionLine;