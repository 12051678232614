import React from 'react';
import {clsx} from "clsx";

function Tabs({tabs, get, set}) {
	if (!tabs) return null;
	return (
		<div className="my-2">
			{/*<div className="sm:hidden">*/}
			{/*	<label htmlFor="tabs" className="sr-only">*/}
			{/*		Select a tab*/}
			{/*	</label>*/}
			{/*	<select*/}
			{/*		id="tabs"*/}
			{/*		name="tabs"*/}
			{/*		onChange={() => set(tab?.column)}*/}
			{/*		className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"*/}
			{/*		defaultValue={tabs.find((tab) => tab?.column === get).name}*/}
			{/*	>*/}
			{/*		{tabs.map((tab) => (*/}
			{/*			<option key={tab?.column}>{tab.name}</option>*/}
			{/*		))}*/}
			{/*	</select>*/}
			{/*</div>*/}
			<div className="hidden sm:block">
				<div className="border-b border-gray-200">
					<nav className="-mb-px flex gap-x-5" aria-label="Tabs">
						{tabs.map((tab) => (
							<span
								key={tab?.column}
								onClick={() => set(tab?.column)}
								className={clsx(
									tab?.column === get
										? 'border-blue-500 text-blue-600'
										: 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
									'cursor-pointer select-none flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
								)}
								aria-current={tab?.column === get ? 'page' : undefined}
							>
                {tab?.name}
								{tab?.count ? (
									<span
										className={clsx(
											tab?.column === get ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
											'ltr:ml-2 rtl:mr-2 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
										)}
									>
                    {tab?.count}
                  </span>
								) : null}
              </span>
						))}
					</nav>
				</div>
			</div>
		</div>
	
	);
}

export default Tabs;
