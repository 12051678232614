import React, {useEffect, useMemo, useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import TextInput from "../../../components/TextInput";
import ProductFormItem from "../../../components/ProductFormItem";
import {PlusIcon} from "@heroicons/react/24/solid";
import Button from "../../../components/Button";
import Toggle from "../../../components/Toggle";
import SelectInput from "../../../components/SelectInput";
import usePaymentFormStore from "../../../store/payment-form";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {http} from "../../../utils/http";
import MutationStatusMessage from "../../../components/MutationStatusMessage";
import {useNavigate} from "react-router-dom";
import {apollo} from "../../../utils/apollo";
import {CREATE_SETTLEMENT} from "../../../utils/graphql";

function SettlementCreate() {
	const [amount, setAmount] = useState('1');
	const [date, setDate] = useState('');
	const queryClient = useQueryClient()
	const navigate = useNavigate();
	
	const createSettlementMutation = useMutation({
		mutationFn: async () => {
			const {data} = await apollo.mutate({
				mutation: CREATE_SETTLEMENT,
				variables: {
					input: {
						amount,
						date
					}
				},
			});
			return data?.getSettlement;
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['settlement_list']).then(() => {
				setTimeout(() => navigate("/merchant/settlements"), 2000);
			});
		},
	})
	
	return (<DashboardLayout>
		<PageHeader back="/merchant/settlements" title="Create Settlement" description="This page provides you with the capability to generate new payment links for seamless transactions."/>
		
		<MutationStatusMessage mutation={createSettlementMutation}/>
		
		{/*<div className="mt-8 flex items-center gap-6">*/}
		{/*	<Toggle get={useExistingCustomer} set={value => setUseExistingCustomer(value)}>Use existing customer details</Toggle>*/}
		{/*</div>*/}
		
		<div className="mt-8 grid gap-x-6 gap-y-8 grid-cols-12">
			{/*<div className="col-span-12 sm:col-span-6 lg:col-span-3">*/}
			{/*	<SelectInput name="customer" label="Customer Uuid" id="customer" get={customerUuid} set={setCustomerUuid} disabled={!useExistingCustomer}>*/}
			{/*		<option value=""></option>*/}
			{/*		{customersList && customersList?.map((customer) => (<option key={customer?.customer_uuid} value={customer?.customer_uuid}>{customer?.name}</option>))}*/}
			{/*	</SelectInput>*/}
			{/*</div>*/}
			
			<div className="col-span-12 sm:col-span-6 lg:col-span-3">
				<TextInput name="amount" label="Amount" id="amount" type="text" get={amount} set={setAmount} required={true}/>
			</div>
			
			<div className="col-span-12 sm:col-span-6 lg:col-span-3">
				<TextInput name="date" label="Settlement Date" id="date" type="date" get={date} set={setDate} required={true}/>
			</div>
			
			{/*<div className="col-span-12 sm:col-span-6 lg:col-span-3">*/}
			{/*	<TextInput name="email" label="Email" id="email" type="email" get={email} set={setEmail} disabled={useExistingCustomer}/>*/}
			{/*</div>*/}
			
			{/*<div className="col-span-12 sm:col-span-6 lg:col-span-3">*/}
			{/*	<TextInput name="phone" label="Phone" id="phone" type="text" get={phone} set={setPhone} disabled={useExistingCustomer}/>*/}
			{/*</div>*/}
		</div>
		
		<div className="mt-6">
			<div className="mt-6 flex flex-wrap items-center gap-4">
				<Button type="button" onClick={() => createSettlementMutation.mutate()}>
					<span className="flex items-center gap-2">Request Settlement</span>
				</Button>
			</div>
		
		</div>
	</DashboardLayout>);
}

export default SettlementCreate;
