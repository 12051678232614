import React from 'react';

function InvoiceField({name, value, className, hide}) {
    if (hide) return <></>;
    return (<div className={className}>
        {name && <div className="uppercase text-xs text-gray-600">{name}</div>}
        <div className="">{value || 'N/A'}</div>
    </div>)
}

export default InvoiceField;