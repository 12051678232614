import React, {Fragment} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PaperClipIcon } from '@heroicons/react/24/outline'
import {PaperAirplaneIcon} from "@heroicons/react/24/solid";

function PaymentLinkDialog({get, set}) {
  const clearLink = ()=> {
    set(null)
  }
  return (
    <Transition.Root show={!!get} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={clearLink}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <PaperAirplaneIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Payment Link
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 font-mono leading-6 tracking-tight">
	                      {get}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="bg-gradient-to-br from-green-500 to-blue-500 hover:from-green-400 hover:to-blue-400 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => {
                      copyToClipboard(get);
                      clearLink()
                    }}
                  >
                    Copy Link
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function copyToClipboard(text) {
    // Create new element
    var el = document.createElement('textarea');
    // Set the value (string to be copied)
    el.value = text;
    // Set non-editable
    el.setAttribute('readonly', '');
    // Append the element to body
    document.body.appendChild(el);
    // The text is selected
    el.select();
    // Execute copy command
    document.execCommand('copy');
    // Remove the element
    document.body.removeChild(el);
}
export default PaymentLinkDialog;