import {CheckIcon} from '@heroicons/react/24/solid'
import {clsx} from "clsx";
import {cn} from "../../utils/style";


function StepPanel({step, onClick, registerForm}) {
	
	const steps = [
		{
			id: 1,
			name: 'General Information',
			description: 'This involves providing your basic information, including your name, address, and contact details.',
			status: step === 1 ? 'current' : (step > 1 ? 'complete' : 'upcoming'),
		},
		{
			id: 2,
			name: 'Upload Documents',
			description: 'In this part, you are required to upload necessary documents such as your identity proof and address proof for verification purposes.',
			status: step === 2 ? 'current' : (step > 2 ? 'complete' : 'upcoming'),
		},
		{
			id: 3,
			name: 'Bank Information',
			description: 'This requires you to provide information about your bank account for any future financial transactions.',
			status: step === 3 ? 'current' : (step > 3 ? 'complete' : 'upcoming'),
		},
		// {
		// 	id: 4,
		// 	name: 'Sign Up',
		// 	description: 'Merchant user authentication details',
		// 	status: step === 4 ? 'current' : (step > 4 ? 'complete' : 'upcoming'),
		// },
	]
	//registerForm
	return (
		<div className="xl:border-b xl:border-t xl:border-gray-200">
			<nav className="mx-auto" aria-label="Progress">
				<ol
					role="list"
					className="overflow-hidden rounded-md xl:flex xl:rounded-none xl:border-l xl:border-r xl:border-gray-200"
				>
					{steps.map((step, stepIdx) => (
						<li key={step.id} onClick={() => onClick(step.id)} className="cursor-pointer relative overflow-hidden xl:flex-1 bg-white">
							<div
								className={clsx(
									stepIdx === 0 ? 'rounded-t-md border-b-0' : '',
									stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '',
									'overflow-hidden border border-gray-200 xl:border-0'
								)}
							>
								{step.status === 'complete' ? (
									<div className="group">
										<span
											className="absolute ltr:left-0 rtl:right-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 xl:bottom-0 xl:top-auto xl:h-1 xl:w-full"
											aria-hidden="true"
										/>
										<span
											className={clsx(
												stepIdx !== 0 ? 'xl:pl-9' : '',
												'flex items-start px-6 py-5 text-sm font-medium'
											)}
										>
											<span className="flex-shrink-0">
												<span className="flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-br from-green-500 to-blue-500">
													<CheckIcon className="h-6 w-6 text-white" aria-hidden="true"/>
												</span>
											</span>
											<span className="ltr:ml-4 rtl:mr-4 mt-0.5 flex min-w-0 flex-col">
												<span className="text-sm font-medium">{step.name}</span>
												<span className="text-sm font-medium text-gray-500">{step.description}</span>
											</span>
										</span>
									</div>
								) : step.status === 'current' ? (
									<div onClick={() => onClick(step.id)} aria-current="step">
										<span
											className="absolute ltr:left-0 rtl:right-0 top-0 h-full w-1 bg-gradient-to-br from-green-500 to-blue-500 xl:bottom-0 xl:top-auto xl:h-1 xl:w-full"
											aria-hidden="true"
										/>
										<span
											className={cn(
												'flex items-start px-6 py-5 text-sm font-medium',
												{
													'xl:pl-9': stepIdx !== 0
												}
											)}
										>
													<span className="flex-shrink-0">
													<span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-blue-500">
													<span className="text-blue-500">{step.id}</span>
													</span>
													</span>
													<span className="ltr:ml-4 rtl:mr-4 mt-0.5 flex min-w-0 flex-col">
													<span className="text-sm font-medium text-blue-500">{step.name}</span>
									<span className="text-sm font-medium text-gray-500">{step.description}</span>
									</span>
									</span>
									</div>
								) : (
									<div onClick={() => onClick(step.id)} className="group">
										<span
											className="absolute ltr:left-0 rtl:right-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 xl:bottom-0 xl:top-auto xl:h-1 xl:w-full"
											aria-hidden="true"
										/>
										<span
											className={clsx(
												stepIdx !== 0 ? 'xl:pl-9' : '',
												'flex items-start px-6 py-5 text-sm font-medium'
											)}
										>
											<span className="flex-shrink-0">
												<span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
													<span className="text-gray-500">{step.id}</span>
												</span>
											</span>
											<span className="ltr:ml-4 rtl:mr-4 mt-0.5 flex min-w-0 flex-col">
												<span className="text-sm font-medium text-gray-500">{step.name}</span>
												<span className="text-sm font-medium text-gray-500">{step.description}</span>
											</span>
										</span>
									</div>
								)}
								
								{stepIdx !== 0 ? (
									<>
										{/* Separator */}
										<div className="absolute inset-0 ltr:left-0 rtl:right-0 rtl:rotate-180 top-0 hidden w-3 xl:block" aria-hidden="true">
											<svg
												className="h-full w-full text-gray-300"
												viewBox="0 0 12 82"
												fill="none"
												preserveAspectRatio="none"
											>
												<path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke"/>
											</svg>
										</div>
									</>
								) : null}
							</div>
						</li>
					))}
				
				</ol>
			</nav>
		</div>
	)
}

export default StepPanel;