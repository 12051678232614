import React from 'react';
import {Switch} from '@headlessui/react'
import {clsx} from "clsx";

function Toggle({children, get, set, disabled}) {
	
	return (
		<Switch.Group as="div" className="flex items-center">
			<Switch
				checked={get}
				onChange={() => !disabled && set(!get)}
				className={clsx(
					get ? 'bg-gradient-to-br from-green-500 to-blue-500' : 'bg-gray-200',
					'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
				)}
			>
        <span
	        aria-hidden="true"
	        className={clsx(
		        get ? 'ltr:translate-x-5 rtl:-translate-x-5' : 'translate-x-0',
		        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
	        )}
        />
			</Switch>
			<Switch.Label as="span" className="ltr:ml-3 rtl:mr-3 text-sm select-none cursor-pointer">
				<span className="font-medium text-gray-900">{children}</span>
			</Switch.Label>
		</Switch.Group>
	)
}

export default Toggle;