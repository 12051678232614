import React from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import UpgradeBusinessPlus from "../../../components/UpgradeBusinessPlus";

function SubUserCreate() {
	return (
		<DashboardLayout>
			<UpgradeBusinessPlus/>
		</DashboardLayout>
	);
}

export default SubUserCreate;