import React, {useRef, useState} from 'react';
import {Link, useLocation, useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import InvoiceLayout from "../layouts/InvoiceLayout";
import ButtonLong from "../components/ButtonLong";
import Loading from "../components/Loading";
import {apollo} from "../utils/apollo";
import {CREATE_DIRECT_PAYMENT_LINK, GET_INVOICE} from "../utils/graphql";
import MoneyHighlight from "../components/MoneyHighlight";
import ReCAPTCHA from 'react-google-recaptcha'
import {useTranslation} from "react-i18next";
import {cn} from "../utils/style";
import Tabby from "../tabby";
import InvoiceField from "../components/InvoiceField";
import InvoiceBanner from "../components/InvoiceBanner";
import PaymentCallback from "../components/PaymentCallback";

function Pay() {
    const {t} = useTranslation();
    const {uuid} = useParams();
    const [showTabbyForm, setShowTabbyForm] = useState(false);
    const recaptcha = useRef()
    const conditionsOfUse = useRef()
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const backendMessage = queryParams.get('message') ?? null;
    const callback = queryParams.get('callback') ?? null;

    const fetchInvoice = useQuery(
        ['invoice', {uuid}],
        async () => {
            const {data} = await apollo.query({
                query: GET_INVOICE, variables: {
                    uuid,
                },
            });
            return data?.getInvoice;
        },
        {
            keepPreviousData: false,
            retry: false
        });
    const invoice = fetchInvoice?.data || null;
    const isSatim = invoice?.gatewayCode === 'satim' || false;
    const createDirectPaymentLinkMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: CREATE_DIRECT_PAYMENT_LINK, variables: {
                    input: {
                        uuid,
                        remarks: "",
                        recaptcha: recaptcha?.current?.getValue() || null,
                        conditions_of_use: conditionsOfUse?.current?.checked || null,
                    }
                },
            });
            return data?.createDirectPaymentLink;
        },
        onSuccess: async (data) => {
            if (!data) return;
            const {method, url, body} = data;

            if (method === 'get' && url) {
                window.location.href = url;
                return;
            }

            if (method === 'post' && url) {

                const parsedData = JSON.parse(body) ?? null;
                if (parsedData) {
                    const form = document.createElement('form');
                    form.method = method;
                    form.name = 'form';
                    form.action = url;
                    for (const key in parsedData) {
                        if (parsedData.hasOwnProperty(key)) {
                            const hiddenField = document.createElement('input');
                            hiddenField.type = 'hidden';

                            hiddenField.name = key;
                            hiddenField.value = parsedData[key];

                            form.appendChild(hiddenField);
                        }
                    }
                    document.body.appendChild(form);
                    try {
                        form.submit();
                    } catch (err) {
                        HTMLFormElement.prototype.submit.call(form);
                    }
                    return;
                }
            }

            alert("Unable to generate payment link. Please try again. Contact support if issue continues.");
        },
        onSettled: async () => {
            recaptcha?.current?.reset()
        }
    })

    if (fetchInvoice.isLoading) return (<InvoiceLayout invoice={invoice}>
        <Loading/>
    </InvoiceLayout>);

    const handlePayButton = () => {
        if (showTabbyForm) {
            // todo: use tabby
        } else {
            createDirectPaymentLinkMutation.mutate();
        }
    }

    if (fetchInvoice.isError) {
        return <InvoiceLayout invoice={invoice}><InvoiceBanner backendMessage={backendMessage} status="unknown_error"/></InvoiceLayout>
    }

    if (fetchInvoice.isSuccess && !invoice) {
        return <InvoiceLayout invoice={invoice}><InvoiceBanner backendMessage={backendMessage}
                                                               status="not_found"/></InvoiceLayout>
    }

    if (fetchInvoice.isSuccess && invoice && callback && ['completed', 'failed', 'canceled'].includes(invoice?.status)) {
        return <PaymentCallback backendMessage={backendMessage} invoice={invoice}/>;
    }

    return (<InvoiceLayout invoice={invoice} test={invoice?.test} showLang={true}>
        {createDirectPaymentLinkMutation.isLoading && <div
            className="z-50 backdrop-blur-sm w-full h-full absolute top-0 left-0 bg-white/20 flex justify-center items-center">
            <Loading message="Redirecting ..."/>
        </div>}

        <InvoiceBanner backendMessage={backendMessage} status={invoice?.status} antiFraud={invoice?.antiFraud}/>

        <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 my-3">
            <InvoiceField name="merchant" value={invoice?.merchant?.merchantName} className="col-span-6"/>
            <InvoiceField name="address" value={invoice?.merchant?.merchantAddress} className="col-span-6"/>
            <InvoiceField name="email" value={invoice?.merchant?.merchantEmail} className="col-span-6"/>
            <InvoiceField name="mobile" value={invoice?.merchant?.merchantPhone} className="col-span-6"/>
        </div>

        <hr className="h-[1px] bg-gray-200 my-8"/>

        <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 my-3">
            <InvoiceField name="date" value={invoice?.createdAt} className="col-span-4"/>
            <InvoiceField name="payment type" value={invoice?.splitPayment ? 'Split Payment' : 'Full'}
                          className="col-span-4"/>
            <InvoiceField name="amount" value={`${invoice?.amount || ''} ${invoice?.currency?.symbol || ''}`}
                          className="col-span-4"/>
            <InvoiceField name="name" value={invoice?.name} className="col-span-4"/>
            <InvoiceField name="phone" value={invoice?.phone} className="col-span-4"/>
            <InvoiceField name="email" value={invoice?.email} className="col-span-4"/>
            {isSatim && <InvoiceField name="Le mode de paiement" value={<>
                <div>Carte CIB/Edahabia</div>
                <img src="/images/satim.png" className="h-12" alt="Carte CIB/Edahabia"/>
            </>} className="col-span-4"/>}
            <InvoiceField name="reference number "
                          value={invoice?.displayReferenceNumber || invoice?.customerReferenceNumber}
                          className="col-span-4"/>
        </div>

        <hr className="h-[1px] bg-gray-200 my-8"/>

        {!!invoice?.products?.length && <>
            <h2 className="text-green-500 text-xl">Products</h2>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold uppercase text-gray-600 sm:pl-0">
                                    Product
                                </th>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left font-semibold uppercase text-xs text-gray-600">
                                    Amount
                                </th>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left font-semibold uppercase text-xs text-gray-600">
                                    VAT%
                                </th>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left font-semibold uppercase text-xs text-gray-600">
                                    Quantity
                                </th>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left font-semibold uppercase text-xs text-gray-600">
                                    Total
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {invoice?.products?.map((product) => (<tr key={product.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {product.title}
                                    <div className="text-gray-500">{product.details}</div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.amount} {invoice?.currency?.symbol}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.vat === 0 ? '' : product.vat}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.quantity}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{product.total} {invoice?.currency?.symbol || ''}</td>
                            </tr>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr className="h-[1px] bg-gray-200 my-8"/>
        </>}

        {showTabbyForm && <Tabby invoice={invoice}/>}

        <div className="sticky bottom-0 left-0 -px-4 bg-gray-50 py-2 border-t sm:border-0 border-gray-200">
            {(['pending', 'failed'].includes(invoice?.status) && isSatim) && <div className="flex mb-2">
                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY}/>
            </div>}
            {(['pending', 'failed'].includes(invoice?.status) && isSatim) &&
                <div className="flex mb-2 gap-x-2 items-center">
                    <input ref={conditionsOfUse} type="checkbox" name="conditionsOfUse" id="conditionsOfUse"/>
                    <div className="flex justify-center items-center gap-x-1">
                        <label htmlFor="conditionsOfUse" className="select-none">J'accepte les</label>
                        <Link to="/conditions-of-use-satim" target="_blank"
                              className="font-semibold underline cursor-pointer">conditions
                            d'utilisation</Link>
                    </div>
                </div>}
            <div className={cn('flex mb-4', {
                'gap-x-2 items-end': isSatim, 'justify-between items-center': !isSatim,
            })}>
                <span className="text-gray-600 font-semibold text-xl">{t('pay.total')}</span>
                <MoneyHighlight value={invoice?.amount} currency={invoice?.currency?.symbol}
                                className={cn('!text-2xl', {
                                    '!text-black': isSatim
                                })}/>
            </div>
            {['pending', 'failed'].includes(invoice?.status) && <div className="flex gap-2">
                <ButtonLong onClick={handlePayButton} className="items-center gap-3">
                    {isSatim && <img src="/images/satim.png" className="h-8" alt="Carte CIB/Edahabia"/>}
                    {t('pay.pay_now')}
                </ButtonLong>
                {/*{(!showTabbyForm) &&*/}
                {/*    <ButtonLong secondary={true} onClick={() => setShowTabbyForm(!showTabbyForm)}*/}
                {/*                className="items-center gap-3">*/}
                {/*        Use Tabby*/}
                {/*    </ButtonLong>}*/}
            </div>}
            {isSatim && <div className="mt-6 flex items-center text-gray-600 justify-between">
                {/*<div>{t('pay.satim_free_toll')}</div>*/}
                <div>En cas de problème de paiement, merci de contacter le numéro vert SATIM 3020</div>
                <img
                    className="shrink-0 w-20"
                    src="/images/3020.svg"
                    alt="SATIM 3020"/>
            </div>}
        </div>
    </InvoiceLayout>);
}

export default Pay;
