import React from 'react';
import {getAppName, getLogoFileName, getWhiteLabelName} from "../utils/whitelabel";
import {cn} from "../utils/style";
import Logo from "../components/Logo";

function PageLayout({title, children}) {
    return (
        <div className="flex max-w-4xl w-full mx-auto flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
            <Logo/>
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                <h1 className="text-center text-gray-800 font-bold text-lg">{title}</h1>
                {children}
            </div>
        </div>
    );
}

export default PageLayout;