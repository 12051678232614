import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import useAuthStore from "../store/auth";

function Impersonate() {
    const {setImpersonateToken} = useAuthStore()
    const {token} = useParams();

    useEffect(() => {
        setImpersonateToken(token);
        window.location.href = "/";
    }, []);

    return (
        <div></div>
    );
}

export default Impersonate;