import {z} from "zod";

export const loginSchema = z.object({
	email: z.string().email(),
	password: z.string().min(8),
});

export const registerSchema = z.object({
	name: z.string(),
	email: z.string().email(),
	password: z.string().min(8),
	password_confirmation: z.string().min(8),
});
