import React from 'react';
import DashboardLayout from "../../layouts/DashboardLayout";

function Invoice() {
	return (
		<DashboardLayout>
			Invoice
		</DashboardLayout>
	);
}

export default Invoice;

