import React from 'react';

function IconButton({children, onClick, type}) {
	return (
		<button
			onClick={e => {
				e.preventDefault();
				onClick(e);
			}}
			type={type || 'button'}
			className="flex items-center justify-center px-2 py-2 text-lg leading-tight bg-gray-200 hover:bg-blue-100 rounded-full hover:outline outline-blue-300 outline-1">
			{children}
		</button>
	);
}

export default IconButton;