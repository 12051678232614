import React from 'react';
import {clsx} from "clsx";
import {cn} from "../../../utils/style";

function RegistrationCountryField({field, show, get, set, readOnly, countries}) {
	if (readOnly)
		return <div key={field.id} className={cn("sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4", {
			'sm:grid': show,
			'hidden': !show,
		})}>
			<label htmlFor={field.field_name} className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
				{field.displayName} <span className="text-red-500/50 text-[10px]" aria-label="Required">{field.required ? '(Required)' : ''}</span>
			</label>
			<div className="mt-2 sm:col-span-2 sm:mt-0">
				<select
					id={field.field_name}
					name={field.field_name}
					autoComplete="country-name"
					value={get}
					disabled={true}
					className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:max-w-xs sm:text-sm sm:leading-6"
				>
					{countries?.map((country) => (<option key={country?.id} value={country?.id}>{country?.englishName}</option>))}
				</select>
			</div>
		</div>
	
	return (
		<div className={cn("sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4", {
			'sm:grid': show,
			'hidden': !show,
		})}>
			<label htmlFor={field.fieldName} className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
				{field.displayName} <span className="text-red-500">{field.required ? '*' : ''}</span>
			</label>
			<div className="mt-2 sm:col-span-2 sm:mt-0">
				<select
					id={field.fieldName}
					name={field.fieldName}
					autoComplete="country-name"
					value={get[field.fieldName]}
					onChange={e => !!set && set(field.fieldName, e.target.value)}
					className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:max-w-xs sm:text-sm sm:leading-6"
				>
					<option>United States</option>
					<option>Canada</option>
					<option>Mexico</option>
				</select>
			</div>
		</div>
	);
}

export default RegistrationCountryField;