import React from 'react';
import {getAppName, getLogoFileName, getWhiteLabelName} from "../utils/whitelabel";
import {cn} from "../utils/style";

function Logo({center}) {
    return (
        <img
            className={cn("w-auto",
                {
                    'mx-auto': center || false
                },
                getWhiteLabelName() === 'TOOTHPICK' ? 'h-10' : 'h-24',)}
            src={`/${getLogoFileName()}`}
            alt={getAppName()}
        />
    );
}

export default Logo;