import React, {useEffect, useMemo, useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import TextInput from "../../../components/TextInput";
import ProductFormItem from "../../../components/ProductFormItem";
import {PlusIcon} from "@heroicons/react/24/solid";
import Button from "../../../components/Button";
import Toggle from "../../../components/Toggle";
import SelectInput from "../../../components/SelectInput";
import usePaymentFormStore from "../../../store/payment-form";
import {useMutation, useQuery, useQueryClient} from "react-query";
import MutationStatusMessage from "../../../components/MutationStatusMessage";
import {useNavigate} from "react-router-dom";
import {apollo} from "../../../utils/apollo";
import {CREATE_PAYMENT, GET_CUSTOMER_LIST} from "../../../utils/graphql";
import {generateRandomString} from "../../../utils/helpers";
import {useDeepMemo} from "@apollo/client/react/hooks/internal";

const CURRENT_DAY = `0${new Date().getDate()}`.slice(-2);
const CURRENT_MONTH = `0${new Date().getMonth() + 1}`.slice(-2);
const CURRENT_YEAR = new Date().getFullYear().toString();

function PaymentCreate() {
    const [date, setDate] = useState({day: CURRENT_DAY, month: CURRENT_MONTH, year: CURRENT_YEAR});
    const {
        useExistingCustomer,
        saveCustomerDetails,
        splitPayment,
        customerUuid,
        name,
        email,
        phone,
        products,
        setUseExistingCustomer,
        setSaveCustomerDetails,
        setSplitPayment,
        setCustomerUuid,
        setName,
        setEmail,
        setPhone,
        setProducts,
        resetPaymentFormAction,
        fillCustomerDetails,
        address,
        setAddress,
        city,
        setCity,
        zipCode,
        setZipCode,
        country,
        setCountry,
        expiresAt,
        setExpiresAt,
    } = usePaymentFormStore();
    const queryClient = useQueryClient()
    const navigate = useNavigate();

    const createPaymentMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: CREATE_PAYMENT, variables: {
                    input: {
                        useExistingCustomer, splitPayment, customerUuid, name, email, phone, products,
                        address, city, zipCode, country, expiresAt,
                        saveCustomerDetails: !useExistingCustomer
                    }
                },
            });
            return data?.createPayment;
        }, onSuccess: () => {
            queryClient.invalidateQueries(['payment_links']).then(() => {
                resetPaymentFormAction();
                setTimeout(() => navigate("/merchant/payments"), 2000);
            });
        },
    })

    const fetchCustomersList = useQuery({
        // queryKey: 'customers', queryFn: () => http.get("/merchant/customers"),
        queryKey: 'customers', queryFn: async () => {
            const {data} = await apollo.query({
                query: GET_CUSTOMER_LIST, variables: {},
            });
            return data?.getCustomerList;
        }, keepPreviousData: false
    })
    const customersList = useMemo(() => fetchCustomersList?.isSuccess ? fetchCustomersList?.data?.data : null, [fetchCustomersList]);

    useEffect(() => {
        if (useExistingCustomer && customerUuid && customersList) {
            const customer = customersList.find((customer) => customer.customerUuid === customerUuid);
            fillCustomerDetails(customer)
        }
    }, [useExistingCustomer, customerUuid, customersList]);

    const totalAmount = useMemo(() => {
        return products.reduce((total, product) => total + Number(product.total), 0) || 0;
    }, [products]);

    const updateProduct = (id, key, rawValue) => {
        // console.log({id, key, value})
        const updatedProducts = products.map((p) => {
            if (p.id === id) {
                let quantity = Number(p.quantity || 1);
                let amount = Number(p.amount || 1);
                let total = Number(p.total || 1);
                let vat = Number(p.vat || 0);

                let value = Number(rawValue || 0);

                switch (key) {
                    case 'quantity':
                        total = (amount * value) * (1 + (vat / 100));
                        rawValue = value;
                        break;
                    case 'amount':
                        total = (value * quantity) * (1 + (vat / 100));
                        rawValue = value;
                        break;
                    case 'total':
                        amount = value / (quantity * (1 + (vat / 100)));
                        rawValue = value;
                        break;
                    case 'vat':
                        total = (amount * quantity) * (1 + (value / 100));
                        rawValue = value;
                        break;
                    default:
                        break;
                }

                return {
                    ...p,
                    amount: Number(amount.toFixed(2)),
                    vat: Number(vat.toFixed(2)),
                    total: Number(total.toFixed(2)),
                    quantity: Number(quantity.toFixed(2)),
                    [key]: rawValue
                };
            }
            return p;
        });
        setProducts(updatedProducts);
    }

    const deleteProduct = (id) => {
        const updatedProducts = products.filter((p) => p.id !== id);
        setProducts(updatedProducts);
    }

    const addProduct = () => {
        setProducts([...products, {
            id: generateRandomString(),
            title: "",
            details: "",
            amount: 0,
            vat: 0,
            quantity: 1,
            total: 0
        }]);
    }

    const selectedDateFormated = useDeepMemo(() => {
        try {
            return new Date(`${date.year}-${date.month}-${date.day}`)
        } catch (e) {
            return null;
        }
    }, [date]);

    return (<DashboardLayout>
        <PageHeader back="/merchant/payments" title="Create Payment Link"
                    description="This page provides you with the capability to generate new payment links for seamless transactions."/>

        <MutationStatusMessage mutation={createPaymentMutation}/>

        <div className="mt-8 flex items-center gap-6">
            <Toggle get={useExistingCustomer} set={value => setUseExistingCustomer(value)}>Use existing customer
                details</Toggle>
        </div>

        <div className="mt-8 grid gap-x-6 gap-y-8 grid-cols-12">
            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <SelectInput name="customer" label="Customer Uuid" id="customer" get={customerUuid}
                             set={setCustomerUuid} disabled={!useExistingCustomer}>
                    <option value=""></option>
                    {customersList && customersList?.map((customer) => (
                        <option key={customer?.customerUuid} value={customer?.customerUuid}>{customer?.name}</option>))}
                </SelectInput>
            </div>

            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <TextInput name="name" label="Name" id="name" type="text" get={name} set={setName} required={true}
                           disabled={useExistingCustomer}/>
            </div>

            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <TextInput name="email" label="Email" id="email" type="email" get={email} set={setEmail}
                           disabled={useExistingCustomer}/>
            </div>

            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <TextInput name="phone" label="Phone" id="phone" type="text" get={phone} set={setPhone}
                           disabled={useExistingCustomer}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <TextInput name="address" label="Address" id="address" type="text" get={address} set={setAddress}
                           required={false}
                           disabled={useExistingCustomer}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <TextInput name="city" label="City" id="city" type="text" get={city} set={setCity} required={false}
                           disabled={useExistingCustomer}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <TextInput name="zipCode" label="Zip Code" id="zipCode" type="text" get={zipCode} set={setZipCode}
                           required={false}
                           disabled={useExistingCustomer}/>
            </div>


            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <TextInput name="country" label="Country" id="country" type="text" get={country} set={setCountry}
                           required={false}
                           disabled={useExistingCustomer}/>
            </div>

            <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                <TextInput name="expires" label="Expires At" id="expires_at" type="datetime-local" get={expiresAt}
                           set={setExpiresAt}
                           format="yyyy-MM-dd"
                           required={false}/>
            </div>

        </div>

        <div className="mt-6">
            <div className="flex items-center justify-between gap-4">
                <h3 className="text-lg text-blue-500 py-2">Products</h3>
                {/*<Button type="button" onClick={addProduct} secondary={true}>*/}
                {/*	<span className="flex items-center gap-2"><PlusIcon className="w-5 h-5"/> Add Product</span>*/}
                {/*</Button>*/}
            </div>
            <div>
                {products.map((product) => (
                    <ProductFormItem key={product?.id} product={product} updateProduct={updateProduct}
                                     deleteProduct={deleteProduct}/>))}

                <div className="flex items-center justify-center mt-4">
                    <button type="button" onClick={addProduct}
                            className="bg-gray-200 hover:bg-gray-200/80 border border-b-4 border-gray-300/60 hover:border-gray-300/40 px-4 py-2 rounded-full">
                        <span className="flex items-center gap-2"><PlusIcon className="w-5 h-5"/> Add Product</span>
                    </button>
                </div>


                <div className="mt-4 flex items-center gap-6 flex-wrap">
                    {/*<Toggle get={splitPayment} set={value => setSplitPayment(value)} disabled={true}>Split Payment</Toggle>*/}
                    {/*<Toggle get={saveCustomerDetails} set={value => setSaveCustomerDetails(value)}>Save customer details</Toggle>*/}
                </div>


                <div className="mt-6 flex flex-wrap items-center gap-4">
                    <Button type="button" onClick={() => createPaymentMutation.mutate()}>
                        <span className="flex items-center gap-2">Generate Payment Link</span>
                    </Button>
                    <Button type="button" onClick={() => resetPaymentFormAction()} secondary={true}>
                        <span className="flex items-center gap-2">Reset Form</span>
                    </Button>
                    <span className="text-lg">
						Total amount: {Number(totalAmount.toFixed(2))}
					</span>
                </div>

            </div>
        </div>
    </DashboardLayout>);
}

export default PaymentCreate;
