import React from 'react';
import {clsx} from "clsx";
import {cn} from "../../../utils/style";

function RegistrationBooleanField({field, show}) {
	return (
		<div className={cn("sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4", {
			'sm:grid': show,
			'hidden': !show,
		})}>
			<label htmlFor={field.fieldName} className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
				{field.displayName} <span className="text-red-500/50 text-[10px]" aria-label="Required">{field.required ? '(Required)' : ''}</span>
			</label>
			<div className="mt-2 sm:col-span-2 sm:mt-0">
				toggle
			</div>
		</div>
	);
}

export default RegistrationBooleanField;