import React, {useState} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {http} from "../../../utils/http";
import {useMutation, useQueryClient} from "react-query";
import {apollo} from "../../../utils/apollo";
import {CREATE_BULK_PAYMENT} from "../../../utils/graphql";
import {cn} from "../../../utils/style";
import {ArrowDownTrayIcon, PhotoIcon, TrashIcon} from "@heroicons/react/24/solid";
import ButtonLink from "../../../components/ButtonLink";
import Button from "../../../components/Button";
import {useNavigate} from "react-router-dom";
import Loading from "../../../components/Loading";
import {toast} from "react-toastify";

function PaymentCreateBulk() {
    const queryClient = useQueryClient()
    const navigate = useNavigate();

    const [uploading, setUploading] = useState(false);
    const [errorLines, setErrorLines] = useState([]);
    const [file, setFile] = useState({
        fileName: null,
        fileUrl: null,
        mimeType: null,
    });

    const resetFileField = () => {
        setFile({
            fileName: null,
            fileUrl: null,
            mimeType: null,
        });
        setErrorLines([])
    }

    const handleFileUpload = async (event) => {
        setUploading(true);
        try {
            const file = event.target.files[0];
            if (!file) return;
            const formData = new FormData();
            formData.append('file', file);
            const response = await http.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const {fileName, fileUrl, mimeType} = response?.data;
            if (fileName) {
                setFile({
                    fileName,
                    fileUrl,
                    mimeType
                });
            } else {
                resetFileField();
            }
        } catch (error) {
            console.error(error);
        }
        setUploading(false);
    }

    const createBulkPaymentMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: CREATE_BULK_PAYMENT, variables: {
                    input: {
                        fileName: file?.fileName,
                        fileUrl: file?.fileUrl,
                        mimeType: file?.mimeType
                    }
                },
            });
            return data?.createBulkPayment;
        },
        onSuccess: (data) => {
            if (data.success && !data?.errorLines?.length) {
                toast.success('All payments have been successfully created.')
                queryClient.invalidateQueries(['payment_links']).then(() => {
                    setTimeout(() => navigate("/merchant/payments"), 2000);
                });
            }
            if (data.success && !!data?.errorLines?.length) {
                toast.error('Some payments could not be processed successfully.')
                setErrorLines(data?.errorLines);
            }
        },
    });

    return (
        <DashboardLayout>
            <PageHeader back="/merchant/payments" title="Create Bulk Payment Links"
                        description="This page provides you with the capability to generate bulk payment links for seamless transactions."/>

            <div>
                <div className={cn("sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4 sm:grid")}>
                    <label htmlFor="payment-bulk-upload-file"
                           className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        CSV File <span className="text-red-500">*</span>
                    </label>
                    <div className="relative mt-2 sm:col-span-2 sm:mt-0 overflow-hidden">
                        {uploading && <div
                            className="backdrop-blur-sm z-40 w-full h-full absolute top-0 left-0 bg-white/20 flex justify-center items-center">
                            <Loading message="Uploading ..."/>
                        </div>}

                        {file?.fileUrl && <div>
                            <div className="mb-1 overflow-x-auto">
                                <p className="text-sm text-gray-500 font-mono leading-6 tracking-tight">
                                    {file?.fileUrl}
                                </p>
                            </div>
                            <div className="flex gap-3 items-center">
                                <ButtonLink href={file?.fileUrl} target="_blank"
                                            className="flex items-center justify-center gap-2">
                                    <ArrowDownTrayIcon className="h-4 w-4 text-white"/>
                                    <span>Download</span>
                                </ButtonLink>
                                <Button secondary={true} onClick={resetFileField}
                                        className="flex items-center justify-center gap-2">
                                    <TrashIcon className="h-4 w-4 text-white"/>
                                    <span>Clear</span>
                                </Button>
                            </div>
                        </div>}

                        {!file?.fileName && <div
                            className="flex max-w-2xl justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 bg-white">
                            <div className="text-center">
                                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true"/>
                                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                    <label
                                        htmlFor="payment-bulk-upload-file"
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text--blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring--blue-500 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                        <span>Upload a file</span>
                                        <input id="payment-bulk-upload-file" name="payment-bulk-upload-file"
                                               onChange={handleFileUpload}
                                               accept=".csv"
                                               type="file" className="sr-only"/>
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs leading-5 text-gray-600">CSV up to 10MB</p>
                            </div>
                        </div>}
                    </div>
                </div>

                {!!errorLines?.length && <div className="mt-6 flex flex-wrap items-center gap-4">
                    <label htmlFor="payment-bulk-errors"
                           className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Error Lines
                    </label>
                    <textarea name="payment-bulk-errors" id="payment-bulk-errors" cols="30" rows="10"
                              className="block disabled:opacity-60 disabled:bg-gray-200 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                              value={errorLines.join("\n")} readOnly={true}></textarea>
                </div>}
                <div className="mt-6 flex flex-wrap items-center gap-4">
                    <Button disabled={createBulkPaymentMutation.isLoading || !file?.fileName} type="button"
                            onClick={() => {
                                setErrorLines([]);
                                createBulkPaymentMutation.mutate();
                            }}>
                        <span className="flex items-center gap-2">Generate Payment Links</span>
                    </Button>
                </div>

                <div className="mt-6 flex flex-wrap items-center gap-4 prose w-full max-w-full">
                    <h3>Download Template File</h3>
                    <p>To facilitate the process of uploading bulk payments, we provide a template file that outlines
                        the required format and necessary fields. This ensures that all your payment details are
                        correctly structured and can be processed efficiently. Please follow the steps below to download
                        and use the template:</p>
                    <ButtonLink href={`${process.env.REACT_APP_PAYD_API_BASE_URL}/template.csv`} target="_blank" secondary={true}>Download Template</ButtonLink>
                    <ol>
                        <li><strong>Download the Template</strong>: Click on the link provided to download the bulk payment template CSV
                            file.
                        </li>
                        <li><strong>Fill in the Required Information</strong>: Open the downloaded template using any spreadsheet
                            software (e.g., Microsoft Excel, Google Sheets). You will see columns for each required
                            field:
                            <ul>
                                <li><strong>Client Name</strong>: Full name of the client (e.g., Joe Doe).</li>
                                <li><strong>Client Email</strong>: Client's email address (e.g., johndoe@example.com).</li>
                                <li><strong>Client Phone</strong>: Client's phone number (e.g., 1234567890).</li>
                                <li><strong>Amount</strong>: Payment amount (e.g., 150, which equals to 1.50 in the merchant currency).</li>
                                <li><strong>Customer Reference Number</strong>: A unique reference number for the customer (e.g.,
                                    CRN12345).
                                </li>
                                <li><strong>Split Payment</strong>: Indicate whether the payment is split (TRUE or FALSE).</li>
                                <li><strong>Expires At</strong>: Expiration date and time for the payment (e.g., 31/12/2024 23:59).</li>
                                <li><strong>Address</strong>: Client's address (e.g., 123 Main St).</li>
                                <li><strong>City</strong>: Client's city (e.g., Metropolis).</li>
                                <li><strong>Zip Code</strong>: Postal code for the client's address (e.g., 12345).</li>
                                <li><strong>Country</strong>: Country code (e.g., US).</li>
                            </ul>
                        </li>
                        <li><strong>Save the Completed File</strong>: After entering the required information for each client, save the
                            file in CSV format.
                        </li>
                        <li><strong>Upload the File</strong>: Return to this page and upload your completed template to process the bulk
                            payments.
                        </li>
                    </ol>
                    <p>By using the provided template, you help ensure that your bulk payment data is accurately
                        captured and processed. If you have any questions or encounter any issues, please refer to our
                        help section or contact our support team for assistance.</p>

                </div>

            </div>
        </DashboardLayout>
    );
}

export default PaymentCreateBulk;