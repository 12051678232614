import {create} from 'zustand'

const useRegistrationFormStore = create((set, get) => ({
	step: 0,
	
	registrationCountry: "-1",
	
	dynamicFormValue: {},
	
	credentials: {
		name: '',
		email: '',
		password: '',
		confirm_password: '',
	},
	
	setCredentials: (key, value) => set((state) => {
		let newCredentials = {...state.credentials, [key]: value};
		return {credentials: newCredentials};
	}),
	
	setDynamicFormValue: (key, value) => set((state) => {
		let newDynamicFormValue = {...state.dynamicFormValue, [key]: value};
		Object.keys(newDynamicFormValue).forEach((key) => {
			if (newDynamicFormValue[key] === null) {
				delete newDynamicFormValue[key];
			}
		});
		return {dynamicFormValue: newDynamicFormValue};
	}),
	
	setDynamicFormValues: (values) => set((state) => {
		return {dynamicFormValue: {...state.dynamicFormValue, ...values}};
	}),
	
	setStep: (value) => set({step: value}),
	setName: (value) => set({name: value}),
	setEmail: (value) => set({email: value}),
	setPhone: (value) => set({phone: value}),
	
	getCountry: () => get().dynamicFormValue['country'] || '',
	// resetRegistrationFormAction: () => {
	// 	set({
	// 		name: '',
	// 		email: '',
	// 		phone: '',
	// 	})
	// }
}))

export default useRegistrationFormStore;