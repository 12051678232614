import React from 'react';
import {clsx} from "clsx";

function ButtonLink({children, href, target, secondary, className}) {
	return (
		<a
			href={href}
			target={target || '_self'}
			className={clsx(
				className,
				secondary
					? 'from-red-500 to-orange-500 hover:from-red-400 hover:to-orange-400'
					: 'from-green-500 to-blue-500 hover:from-green-400 hover:to-blue-400',
				"block rounded-md bg-gradient-to-br px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500")}
		>
			{children}
		</a>
	);
}

export default ButtonLink;